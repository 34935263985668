<template>
  <v-container fluid class="px-6 py-6" v-if="school">
    <v-toolbar color="transparent" flat class="mt-0">
      <v-tabs v-model="tab" color="primary accent-4">
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="(item, iindex) in items" :key="iindex">
          {{ item.name }}</v-tab
        >
      </v-tabs>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, cndex) in items" :key="cndex">
        <v-container style="height: 800px; overflow-y: auto">
          <template v-if="item.children">
            <info-tab
              :item="citem"
              :school="school"
              v-for="(citem, cindex) in item.children"
              :key="cindex"
            ></info-tab>
          </template>
          <template v-else>
            <info-tab :item="item" :school="school"></info-tab>
          </template>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    InfoTab: () => import("./Widgets/InfoTab"),
  },
  data: () => ({
    school: null,
    tab: null,
    hhh: 400,
    items: [
      {
        name: "Бага анги",
        children: [
          { name: "Заавал үзэx xичээл", fbpath: "mustLessonsCategoriesI" },
          { name: "Сонгон үзэx xичээл", fbpath: "selectLessonsCategoriesI" },
        ],
      },
      {
        name: "Дунд анги",
        children: [
          { name: "Заавал үзэx xичээл", fbpath: "mustLessonsCategoriesII" },
          { name: "Сонгон үзэx xичээл", fbpath: "selectLessonsCategoriesII" },
        ],
      },
      {
        name: "Аxлаx анги",
        children: [
          { name: "Заавал үзэx xичээл", fbpath: "mustLessonsCategoriesIII" },
          { name: "Сонгон үзэx xичээл", fbpath: "selectLessonsCategoriesIII" },
        ],
      },
      //
    ],
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
        yyschool() {
      return fb.yyschool;
    },
  },
  created() {
    this._read();
  },

  methods: {
    async _read() {
      var school = await fb.db.doc(this.yyschool).get();
      if (school.exists) {
        this.school = school.data();
        this.school.id = school.id;
        this.school.ref = school.ref;
      }
    },
  },
};
</script>
